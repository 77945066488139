import React from 'react'
import Link from 'gatsby-link'
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import panel from '../components/panel.module.css'
import styles from './contact.module.css'
import {Helmet} from 'react-helmet'

const ContactPage = () => (
    <div>
        <Header />
        <Helmet>
            <title>Contact</title>
        </Helmet>
        <div className={panel.panel}>
            <div className={panel.centeredText}>
                <h1 className={panel.h}>
                    Contact
                </h1>
                <div className={styles.line}>
                    <p> 
                        email: rien@undernewsuns.com
                    </p>
                    <p>
                        twitter: <a href="https://twitter.com/UnderNewSuns">@UnderNewSuns</a>
                    </p>
                    <p>
                        discord: <a href="https://discord.gg/V8fa6rp">https://discord.gg/V8fa6rp</a>
                    </p>
                </div>
            </div>
        </div>
        <Footer />
    </div>
)

export default ContactPage